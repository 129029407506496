import { App, URLOpenListenerEvent } from "@capacitor/app";

import { createRouter, createWebHistory } from "@ionic/vue-router";
import type { RouteRecordRaw } from "vue-router";

import { isLoggedIn } from "@/services/api/authService";

import Login from "@/pages/LoginPage.vue";
import ResetPassword from "@/pages/ResetPasswordPage.vue";
import Signup from "@/pages/SignupPage.vue";
import Home from "../pages/HomePage.vue";

/* Deep Link Listener */

App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  // Example url: https://vd.al/places/0f8a3a5f-5e3a-4f8a-8a3a-5e3a0f8a3a5f
  // slug = /places/0f8a3a5f-5e3a-4f8a-8a3a-5e3a0f8a3a5f
  const slug = event.url.split(".al").pop();

  // if no valid route, fall through to a no-op
  if (!slug) return;

  if (
    /^\/places\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(slug)
  ) {
    router.push({
      name: "place",
      params: {
        id: slug.split("/").pop(),
      },
    });
  }

  if (/^\/tags\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(slug)) {
    router.push({
      name: "tag",
      params: {
        id: slug.split("/").pop(),
      },
    });
  }
});

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Home,
    name: "home",
    children: [
      {
        path: "",
        redirect: { name: "feed" },
      },
      {
        path: "/feed",
        name: "feed",
        component: () => import("@/pages/FeedPage.vue"),
      },
      {
        path: "/explore",
        name: "explore",
        component: () => import("@/pages/Places/ExplorePage.vue"),
      },
      {
        path: "/places/:id",
        name: "place",
        component: () => import("@/pages/Place/PlacePage.vue"),
      },
      {
        path: "/tags",
        name: "tags",
        component: () => import("@/pages/Tags/TagsListPage.vue"),
      },

      {
        path: "/tags/:id",
        name: "tag",
        component: () => import("@/pages/TagPage.vue"),
      },
      {
        path: "/leaderboard",
        name: "leaderboard",
        component: () => import("@/pages/Leaderboard/LeaderboardPage.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/pages/SettingsPage.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/pages/ProfilePage.vue"),
      },
      {
        path: "/profile/nerd_stats",
        name: "nerd_stats",
        component: () => import("@/pages/Profile/StatsForNerdsPage.vue"),
      },
      {
        path: "/profile/my/places",
        name: "profile_my_places",
        component: () => import("@/pages/Profile/MyPlacesPage.vue"),
      },
      {
        path: "/profile/my/tags",
        name: "profile_my_tags",
        component: () => import("@/pages/Profile/MyTagsPage.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/pages/NotificationsPage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/reset_password",
    name: "reset_password",
    component: ResetPassword,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(`to: ${to.path}, from: ${from.path}`);

  const val = await isLoggedIn();
  if (val) {
    next();
    return;
  } else {
    if (["login", "signup", "reset_password"].includes(to.name as string)) {
      next();
      return;
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
