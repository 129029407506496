import BaseModel from "./BaseModel";
import type TagStatsModel from "./TagStatsModel";

export default class UserModel extends BaseModel {
  type = "user";
  declare readonly email: string;
  declare readonly username: string;
  declare readonly dateJoined: string;
  declare tagStats: TagStatsModel;

  public get isMattMitchOrOwen() {
    return ["matt", "mitch", "owen"].includes(this.username);
  }

  toString() {
    return this.email;
  }
}
