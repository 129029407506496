<script setup lang="ts">
  import { useHaptics } from "@/composables/useHaptics";
  import {
    compass,
    compassOutline,
    newspaper,
    newspaperOutline,
    notifications,
    notificationsOutline,
  } from "ionicons/icons";

  const url = window.location.href;
  let activeTab = ref(url);

  const tabs = computed(() => {
    return [
      {
        name: "feed",
        route: "/feed",
        label: "Feed",
        icon: activeTab.value.includes("feed") ? newspaper : newspaperOutline,
        class: "feed-tab",
      },
      {
        name: "explore",
        route: "/explore",
        label: "Explore",
        icon: activeTab.value.includes("explore") ? compass : compassOutline,
        class: "place-tab",
      },
      {
        name: "notifications",
        route: "/notifications",
        label: "Notifications",
        icon: activeTab.value.includes("notifications") ? notifications : notificationsOutline,
        class: "notifications-tab",
      },
    ];
  });

  const setSelected = (tab: Record<string, string>) => {
    activeTab.value = tab.name;
  };
</script>

<template>
  <IonPage>
    <IonContent>
      <IonTabs>
        <IonRouterOutlet />
        <IonTabBar slot="bottom">
          <IonTabButton
            v-for="tab in tabs"
            :key="tab.name"
            v-haptics
            :tab="tab.name"
            :href="tab.route"
            @mousedown="useHaptics()"
            @click="setSelected(tab)"
          >
            <IonIcon :icon="tab.icon" :class="tab.class" />
            <IonLabel class="tab-label">{{ tab.label }}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonContent>
  </IonPage>
</template>

<style lang="scss" scoped>
  ion-tab-bar {
    border: none;
  }

  .tab-label {
    font-size: 11px;
  }

  .info-tab {
    margin-left: 2px;
  }
</style>
